@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;700&display=swap');

$tablet-width: 768px;
$desktop-width: 1024px;
$color-background: #1f1f1f;
$color-text: #cfcfcf;
$color-icon-react: #4d4dff;
$color-icon-sass: #c76494;
$color-icon-css3: #016cb4;
$color-icon-html5: #de4c25;
$color-icon-javascript: #f3cf01;
$color-icon-git: #e84d31;
$color-icon-bootstrap: #8512f3;
$color-icon-node: #26ac56;
$color-icon-python: #f7c738;
$color-icon-python-secondary: #326994;
$color-icon-arduino: #009298;
$color-icon-raspberry: #bf1d47;
$color-icon-angellist: #098901;
$color-icon-linkedin: #0077b5;
$color-icon-email: #bab53a;

@mixin mobile {
	@media (max-width: #{$tablet-width - 1px}) {
		@content;
	}
}

@mixin tablet {
	@media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
		@content;
	}
}

@mixin desktop {
	@media (min-width: #{$desktop-width}) {
		@content;
	}
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;

	font-family: 'Comfortaa', cursive;
}
p {
	font-size: 1em;
}
html {
	background-color: $color-background;
}

.projects {
	display: flex;
	flex-direction: column;
	align-items: center;

	width: 90%;

	margin-top: 32px;

	&__title {
		margin-bottom: 8px;
	}
}

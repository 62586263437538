@import '../../../../../index.scss';

@mixin text-grdient($color) {
	background: linear-gradient(90deg, $color, #fff);
}

.about {
	> :not(:first-child) {
		margin-top: 40px;
	}
	&__quote {
		font-style: italic;
		span {
			filter: brightness(0.5);
			font-style: normal;
		}
	}
	&__photo {
		border: none;
		width: 175px;
		border-radius: 50%;
	}
	&__bio {
		& > :not(:first-child) {
			margin-top: 48px;
		}
		> div {
			display: flex;
			align-items: flex-end;
			justify-content: space-between;
			margin-right: 64px;
		}
		> p {
			padding: 0 16px;
		}
	}
	&__techs {
		display: flex;
		flex-direction: column;
		align-items: center;

		width: 100%;

		&--title {
			display: flex;
			align-items: center;
			width: 80%;
			b {
				min-width: 245px;
				margin: 0 16px;
			}
			hr {
				width: 30%;
			}
		}

		&--grid {
			display: grid;

			grid-template-columns: repeat(3, 1fr);

			row-gap: 8px;
			justify-items: center;

			width: 100%;
			margin-top: 16px;
			> div {
				color: transparent;
				background-clip: text;
				background-size: 500%;
				background-position: 200% 0;
				transition: all 0.75s ease-in;
				&:hover {
					cursor: default;
				}

				& :not(:first-child) {
					margin-left: 4px;
				}
				:last-child {
					margin-left: 8px;
				}
				&:hover {
					background-position: 0;
				}
			}
		}

		&--react {
			@include text-grdient($color-icon-react);
		}
		&--sass {
			@include text-grdient($color-icon-sass);
		}
		&--css3 {
			@include text-grdient($color-icon-css3);
		}
		&--html5 {
			@include text-grdient($color-icon-html5);
		}
		&--js {
			@include text-grdient($color-icon-javascript);
		}
		&--github {
			@include text-grdient($color-icon-git);
		}
		&--bootstrap {
			@include text-grdient($color-icon-bootstrap);
		}
		&--node {
			@include text-grdient($color-icon-node);
		}
		&--python {
			@include text-grdient($color-icon-python);
		}
		&--raspberry {
			@include text-grdient($color-icon-raspberry);
		}
	}
}
.span {
	&__LED {
		color: $color-icon-python;
		font-weight: 700;
	}
	&__garden {
		color: $color-icon-node;
	}
	&__raspberry {
		color: $color-icon-raspberry;
	}
	&__arduino {
		color: $color-icon-arduino;
	}
}
.icon {
	&__LED {
		float: left;
		font-size: 48px;
		margin: 0 8px;
	}
	&__Morph {
		float: right;

		width: 56px;
		margin: 0 8px;

		text-align: right;
		font-size: 48px;

		transition: all 0.25s ease-in-out;
	}
}

@include mobile {
	.about {
		padding: 0 16px;
		&__techs {
			&--grid {
				grid-template-columns: repeat(2, 1fr);
				margin-bottom: 32px;
			}
		}
	}
}

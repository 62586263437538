@import '../../../../../../index.scss';

.project {
	display: flex;
	align-items: center;

	&__header {
		display: flex;
		& :not(:first-child) {
			margin-left: 4px;
		}
	}
	&__title {
		display: flex;
		&--sub {
			align-self: flex-end;
			font-size: 12px;

			color: #6a6a6a;
		}
	}
	&__image-container {
		display: flex;
		position: relative;
		justify-content: center;

		width: 100%;
		height: 100%;
	}
	&__overlay {
		z-index: 10;
		position: absolute;

		height: 100%;
		width: 100%;

		text-align: center;
		line-height: var(--project-height);
		color: #fff;
		background-color: #00000099;
		opacity: 0;
		transition: all 250ms ease-in-out;
	}
	&__image {
		height: 100%;
		width: 80%;
	}
	&__details {
		&--desc {
			margin-top: 8px;
		}
	}
	&__techs {
		display: flex;
	}
}

@include mobile {
	.project {
		position: relative;

		width: 100%;
		--project-height: 250px;
		height: var(--project-height);

		margin-top: 8px;

		& > * {
			position: absolute;
		}

		&__title {
			&--sub {
				color: #9a9a9a;
			}
		}

		&__details {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;

			padding: 16px;

			height: 100%;
			z-index: 1;

			background: linear-gradient(0deg, #000, #00000044);
		}

		&__image {
			width: 100%;
		}
	}
}

@include desktop {
	.project {
		position: relative;

		--project-height: 300px;
		height: var(--project-height);
		margin-top: 40px;

		&__image-container {
			display: flex;
			position: relative;
			justify-content: center;

			width: 100%;
			height: 100%;
		}

		&__overlay {
			&:hover {
				cursor: pointer;
			}
		}

		&__image {
			width: 100%;
			height: 100%;
		}

		&__details {
			z-index: 1;

			width: 350px;
			transform: translateX(calc(var(--offsetDirection) * 25));
			padding: 16px;

			border-radius: 4px;
			background-color: #000000cc;
			transition: all 250ms ease-in-out;
		}

		&:hover &__details {
			transform: translateX(calc(var(--offsetDirection) * -10));
		}
		&:hover &__overlay {
			opacity: 1;
		}
	}
}
